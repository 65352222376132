loading = function(){
  var left = $(document).width() / 2;
  var top = window.innerHeight / 2;
  $('#loading-position').css('left', left.toString() + 'px');
  $('#loading-position').css('top', top.toString() + 'px');
  $('#main-cover').attr("onclick", "").off('click');;
  $('#main-cover').show();
  $('#loading-position').show();
}

closeLoading = function() {
  $('#main-cover').hide();
  $('#loading-position').hide();
}

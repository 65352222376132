<template>
  <button :class=className @click="emitClick">
    {{text}}
  </button>

</template>

<script>
export default {
  props: {
    onclick: {
      type: Function
    },
    text: {
      type: String,
      default: ""
    },
    className: {
      type: String,
      default: ""
    }
  },
  data(){
    return{

    }
  },
  methods: {
    emitClick(){
      this.$emit('click', this.onclick)
    }
  },
  mounted(){

  }
}
</script>


<style lang="sass" scoped>


</style>

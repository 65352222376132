import Vue from 'vue/dist/vue.esm'
import OrdersIndex from '../components/orders/OrdersIndex';
import OrderEdit from '../components/orders/OrderEdit';

document.addEventListener('DOMContentLoaded', () => {
  const element = document.querySelectorAll('[data-behavior="vue-order-app"]');
  const elementEdit = document.querySelectorAll('[data-behavior="vue-order-edit-app"]');
  if (element.length > 0) {
    const app = new Vue({
      el: '[data-behavior="vue-order-app"]',
      components: {
        'orders': OrdersIndex
      }
    })
  }
  // Order Edit
  if (elementEdit.length > 0) {
    const app = new Vue({
      el: '[data-behavior="vue-order-edit-app"]',
      components: {
        'order-edit': OrderEdit
      }
    })
  }
})

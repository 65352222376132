<template>
  <div class="order-edit-container row">
    <div class="col-12 col-md-6">
      <!-- 訂單明細 -->
      <order-details
      :order_details="orderDetails"/>

    </div>
    <div class="col-12 col-md-6">
      <!-- 訂單商品 -->
      <order-items
      :orderItems="orderItems"
      :orderDetails="orderDetails"
      :orderPrice="orderPriceDetails"/>

      <!-- 訂單人資訊（收件人付款人） -->
      <order-owner
      :order_owners="orderOwners"
      :is_super_admin="is_super_admin"/>

      <!-- 訂單歷程 -->
      <order-histories
      :order_histories="orderHistories"/>

      <!-- 更新 -->
      <div class="card">
        <div class="card-body font-size-s c-text">
          <div class="d-flex align-items-center justify-content-between">
            <span>您可更改部分訂單資訊，請於更改訂單後按下更新按鈕。</span>
            <item-btn className="btn btn-primary" text="更新" @click="updateBtnClick()"></item-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {orderMixin} from './orderMixin.js'
import "../../common.js";
import orderDetailComponent from './orderEditComponents/orderDetailComponent';
import orderItemsComponent from './orderEditComponents/orderItemsComponent';
import orderOwnerComponent from './orderEditComponents/orderOwnerComponent';
import orderHistoriesComponent from './orderEditComponents/orderHistoriesComponent';
import ItemBtn from "../shared/tableComponents/tableItemsComponents/itemBtn";

export default {
  props: {
    order_items: {
      type: Array,
      default: () => []
    },
    is_super_admin: {
      type: Boolean,
      default: false
    },
    order_owners: {
      type: Object,
      default: () => {}
    },
    order_details: {
      type: Object,
      default: () => {}
    },
    order_price_details: {
      type: Object,
      default: () => {}
    },
    order_number: {
      type: String,
      default: ""
    },
    order_histories: {
      type: Array,
      default: () => []
    },
    from_trade: {
      type: Boolean,
      default: false
    }
  },
  mixins: [orderMixin],
  components: {
    "order-details": orderDetailComponent,
    "order-items": orderItemsComponent,
    "order-owner": orderOwnerComponent,
    "order-histories": orderHistoriesComponent,
    "item-btn": ItemBtn
  },
  data: function() {
    return {
      orderDetails: {...this.order_details},
      orderItems: [...this.order_items],
      orderOwners: {...this.order_owners},
      orderHistories: [...this.order_histories],
      orderPriceDetails: {...this.order_price_details}
    }
  },
  methods: {
    updateBtnClick() {
      const orderDetails = this.orderDetails
      const orderOwners = this.orderOwners
      let updateParams = {
        order: {},
        from_trade: this.from_trade
      }
      const orderKey = {
        show_details: ['state'],
        modified: ['tracking_company', 'tracking_number', 'annotate']
      }
      // orderDetails 只需要更新 state, tracking_company, tracking_number, annotate
      Object.keys(orderKey).map(detailKey => {
        orderKey[detailKey].forEach(params => {
          const value = typeof orderDetails[detailKey][params].value == 'object' && orderDetails[detailKey][params].value != null ? orderDetails[detailKey][params].value.code : orderDetails[detailKey][params].value
          updateParams.order[params] = value
        })
      })

      // super admin 才可以更新 orderOwners(訂購人)
      if (this.is_super_admin) {
        updateParams["shipping_address"] = {}
        updateParams["trade"] = {}
        const orderOwnerKey = {
          details: ['name', 'phone'],
          address: ['country', 'city', 'district', 'zip', 'address1', 'address2', 'st_code']
        }

        Object.keys(orderOwnerKey).map(recipientKey => {
          orderOwnerKey[recipientKey].forEach(params => {
            if (orderOwners.recipient[recipientKey].hasOwnProperty(params))
              updateParams.shipping_address[params] = orderOwners.recipient[recipientKey][params].value
          });
        })
        // updateParams.trade.email = orderOwners.payerEmail.value
      }

      // 將下方參數往後傳即可
      this.updateOrder(this.order_number, updateParams)
        .then(result => {
          this.noticeMsg("orderUpdateSuccess", true)
        })
        .catch(error => {
          const msgData = (error.response != undefined && error.response.data.msg != undefined) ? error.response.data.msg : "default"
          this.alertMsg(msgData)
        });
    }
  },
  mounted() {
  }
}
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- 商品pick up -->
          <div class="card-tab">
            <div class="form-group-title">
              商品pick up
              <small class="tips">上限2區塊</small>
            </div>
            <div class="text-center">
              <div class="row">
                <template v-for="(pickUp, key) in pickUps">
                  <div class="col-12" :key="`pickUpGroup${key}`">
                    <div class="card text-left p-3">
                      <div class="form-group">
                        <div class="d-flex justify-content-between align-items-start">
                          <label>標題</label>
                          <div @click="removePickUp(key)" class="ml-3 cursor-pointer">
                            <i class="fa fa-times mr-1"></i>
                            <span>刪除商品pick up {{key + 1}}</span>
                          </div>
                        </div>
                        <input class="form-control" v-model="pickUp.title">
                      </div>
                      <div v-if="pickUp.kind_id==2" class="form-group">
                        <ImageUpload
                          v-model=pickUp.file
                          :img-url="pickUp.image_url"
                          :title="`Pickup 宣傳圖`"
                          :tips="`建議尺寸：980x250`"
                          width="980px"
                          height="250px"
                        />
                      </div>

                      <div class="form-group">
                        <MerchandiseSearch
                          v-model="pickUp.merchandise_ids"
                          :number="key"
                          :default-data="defaultData"
                          :max-value="pickUp.kind_id===2 ? 5 : 0"
                          :tips="pickUp.kind_id===2 ? '至多只能5筆資料' : ''"
                          @addProduct=" v => addProductHandle(v, key)"
                          @removeProduct="v => removeProductHandle(v, key)"
                        />
                      </div>

                      <div class="form-group">
                        <div class="row">
                          <div class="col-6 col-sm-2" v-for="(product, index) in pickUp.products" :key="`pickUpGroupProducts${index}`">
                            <div class="card p-2">
                              <input v-model="product.id" class="hide" />
                              <div :class="{ 'sku-default-image': product.image_url.length === 0 }" />
                              <div class="merchandiseImage"><img class="img-fluid" :src="product.image_url" v-if="product.image_url.length > 0" /></div>
                              <div class="mt-2 two-line-dot merchandiseName">{{ product.name }}</div>
                              <div class="mt-2 tips">商品編號：{{ product.id }}</div>
                              <div class="text-center mt-2">
                                <button class="btn btn-default btn-sm" @click.prevent="removeMerchandise(key, index, product.id)">刪除</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <div v-if="pickUps.length<2">
                <Multiselect
                  :options="kindOptions"
                  :show-labels="true"
                  placeholder="新增區塊（請先選類型）"
                  label="name"
                  track-by="id"
                  @select="v => pickupOption(v)" />
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-body text-right">
            <button @click="submitFile" class="btn btn-primary">送出</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import MerchandiseSearch from '../merchandise/SearchPopup';
  import Multiselect from 'vue-multiselect';
  import ImageUpload from '../shared/uploader/ImageUploadPreview'
  const axios = require('axios');
  export default {
    props: {
      pickUpsInit: {
        type: Array,
        default: () => []
      },
      kindOptions: {
        type: Array,
        default: () => []
      },
      defaultData: {
        type: Array,
        default: () => []
      },
    },
    components: {
      MerchandiseSearch,
      Multiselect,
      ImageUpload
    },
    data: function () {
      return {
        pickUps: [...this.pickUpsInit],
        typePicked: [],
      }
    },
    computed: {
    },
    methods: {
      addPickUp(kind_id) {
        let newPickup = {
          products: [],
          kind_id: kind_id,
          merchandise_ids: []
        }
        if (kind_id == 2) {
          newPickup.image_url = ''
        }
        this.pickUps.push(newPickup)
      },
      removeMerchandise(index, key, pid) {
        this.pickUps[index].products.splice(key, 1);
        this.pickUps[index].merchandise_ids = this.pickUps[index].merchandise_ids.filter(mid => +mid !== +pid);
      },
      removePickUp(key){
        this.pickUps.splice(key, 1);
      },
      addProductHandle(prd, idx){
        this.pickUps[idx].products.push(prd)
      },
      removeProductHandle(prd, idx){
        this.pickUps[idx].products = this.pickUps[idx].products.filter( d => prd.id !== d.id)
      },
      pickupOption(v, k){
        this.addPickUp(v.id)
        this.typePicked = []
      },
      submitFile() {
        loading()
        let formData = new FormData();

        // 商品 pick up
        this.pickUps.forEach(( pick, key ) => {
          const fields = [
            'id',
            'title',
            'file',
            'kind_id',
            'merchandise_ids'
          ]
          fields.forEach(k => {
            if (pick[k]) {
              formData.append(`pick_ups[${key}][${k}]`, pick[k])
            }
          })

          formData.append(`pick_ups[${key}][sort]`, key + 1)
          if (pick.file || pick.file === '') formData.append(`pick_ups[${key}][file]`, pick.file)

        });

        axios.post( '/pickup_merchandise',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        ).then(function(){
          closeLoading();
          location.reload();
        });
        // closeLoading();
      }
    }
  }
</script>

<style lang="scss" scoped>
.merchandise-card{
  width: 200px;
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 1px solid rgba(0,0,0,0.125);
  border-radius: .25rem;
  position: relative;

}
.merchandiseImage{
  height: 100%;
  width: 100%;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
.merchandiseName{
  height: 50px;
  line-height: 25px;
}
</style>

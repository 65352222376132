<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">訂單明細</div>
      </div>
      <div class="card-body">
        <div class="form-group states" v-for="(detail, name) in orderDetails.show_details" :key="name">
          <div class="label-title">
            {{detail.label}}
            <span class="ml-2" v-if="detail.options != undefined" :class="`${detail.class_name}`" >{{detail.value.name}}</span>
          </div>
          <div class="value-text" v-if="detail.options == undefined" >{{detail.value}}</div>
          <div class="" v-if="detail.options != undefined">
            <item-select
            :select_options="detail.options"
            :select_hide="true"
            :search_able="false"
            :selected_value="detail.value"
            select_icon=""
            @search_changed="selectorChanged($event,name)"
            emit_type="event"
            select_class_list="orderEditSelector"
            :show_select_label='false'
            :disabled_select='!detail.able_select'
            track_by="code">
            </item-select>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <div class="card-header-title">配送/送貨資料</div>
      </div>
      <div class="card-body">
        <!-- 宅配 -->
        <div v-if="orderDetails.show_details.shipment_type.value == '宅配'">
          <div class="form-group">
            <label>{{orderDetails.modified.tracking_company.label}}</label>
            <input class="form-control" type="text" v-model="orderDetails.modified.tracking_company.value" :rows="orderDetails.modified.tracking_company.rows">
          </div>
          <div class="form-group">
            <label>{{orderDetails.modified.tracking_number.label}}</label>
            <input class="form-control" type="text" v-model="orderDetails.modified.tracking_number.value" :rows="orderDetails.modified.tracking_number.rows">
          </div>
        </div>
        <!-- 7-11 -->
        <div v-else>
          <div class="form-group">
            <label>{{orderDetails.modified.tracking_company.label}}</label>
            <template v-if="orderDetails.modified.print_link">
              <div class="value-text">
                <span>{{orderDetails.modified.tracking_company.value}}</span>
                <a :href="`${orderDetails.modified.print_link.value}`" v-if="orderDetails.modified.print_link.expired" class="ml-2">
                  {{ orderDetails.modified.print_link.label }}
                </a>
                <a :href="`${orderDetails.modified.print_link.value}`" v-else target="_blank" class="ml-2">
                  {{ orderDetails.modified.print_link.label }}
                </a>
              </div>
              <div class="value-text">
                <span>{{orderDetails.modified.print_link.title}}</span>
                <span class="text-muted ml-1" style="font-size: 12px;">{{orderDetails.modified.print_link.hint}}</span>
              </div>
            </template>
          </div>
          <div class="form-group">
            <label>{{orderDetails.modified.tracking_number.label}}</label>
            <div>{{orderDetails.modified.tracking_number.value}}</div>
          </div>
        </div>
        <div class="form-group">
          <label>{{orderDetails.modified.annotate.label}}</label>
          <textarea class="form-control" type="text" v-model="orderDetails.modified.annotate.value" rows='4'></textarea>
        </div>
      </div>
    </div>

    <div class="card" v-if="orderDetails.returns">
      <div class="card-header">
        <div class="card-header-title">退貨資訊</div>
      </div>
      <div class="card-body">
        <div class="form-group" v-for="(detail, name) in orderDetails.returns" :key="name">
          <div class="label-title">
            {{detail.label}}：
            <span class="ml-2">{{detail.value}}</span>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import singleSelectComponent from '../../shared/singleSearchComponent';

export default {
  props: {
    order_details: {
      type: Object,
      default: () => {}
    }
  },
  data(){
    return {
      orderDetails: {...this.order_details}
    }
  },
  components: {
    "item-select": singleSelectComponent,
  },
  methods:{
    selectorChanged(selectedValue, dataType){
      this.orderDetails.show_details[dataType].value = selectedValue
      this.orderDetails.show_details[dataType].class_name = `label ${selectedValue.code}`
    },
  }
}
</script>

<template>
  <form action="/orders/update_many" method="POST" class="update-many-form">
    <input type="hidden" name="authenticity_token" :value="csrf">
    <input type="hidden" name="order_numbers" :value="`${order_numbers.join(',')}`">
    <div class="row">
      <div class="col-12">
        <div class="form-group update-many-form-group mb-0" style="border-top: 0;">
          <div class="form-group-label">已選擇 {{orders.length}} 筆訂單</div>
          <div class="form-group-input">
            <div class="row">
              <div class="col-12 col-sm-3">
                <select class="form-control"
                  id="selectedAction"
                  v-model="selectedAction"
                  @change="selectedActionChange()"
                >
                  <option value="">請選擇</option>
                  <option value="updateState" data-action="updateMany">更改訂單狀態</option>
                  <option
                    value="printPDF"
                    data-action="printPDF"
                    v-if="showPrintBtn"
                  >
                    列印托運單
                  </option>
                </select>
              </div>
              <div
                v-if="stateOptions.length > 0"
                class="col-12 col-sm-3"
              >
                <select
                  name="state"
                  class="form-control"
                  v-model="selectedState"
                  @change="selectedStateChange()"
                >
                  <option value="">請選擇</option>
                  <option
                    v-for="state in stateOptions"
                    :key="state.id"
                    :value="state.code"
                  >{{ state.name }}</option>
                </select>
              </div>
              <div
                v-if="readyAction"
                class="col-12 col-sm-3"
              >
                <button
                  type="button"
                  class="btn filter-action-btn btn-primary"
                  v-on:click="doAction($event)"
                >執行</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
  const axios = require('axios');

  export default {
    props: ["prop_orders"],
    watch: {
      prop_orders: function() {
        this.clearSelected();
        this.orders = this.prop_orders;
        this.order_numbers = this.prop_orders.map((order) => order['number']);
        this.checkPrint();
      }
    },
    data() {
      return {
        csrf: $('meta[name="csrf-token"]').attr('content'),
        stateOptions: [],
        selectedAction: "",
        selectedState: "",
        orders: this.prop_orders,
        order_numbers: this.prop_orders.map((order) => order['number']),
        showPrintBtn: false,
        readyAction: false
      }
    },
    mounted() {
      this.checkPrint();
    },
    methods: {
      checkPrint: function () {
        var prints = this.orders.map((order) => order['printable']);
        if (prints.filter(this.onlyUnique).sort()[0] == true) {
          switch (this.orders[0]['shipping_type']) {
          case 'ecpay_711_b2c':
            this.showPrintBtn = true;
          case 'ecpay_711_c2c':
            if (this.orders.length == 1)
              this.showPrintBtn = true;
          }
        }
      },
      selectedActionChange: function () {
        this.stateOptions = [];
        this.readyAction = false;

        if (this.selectedAction == "updateState") {
          // get each order allow_states
          var actions_states = this.orders.map((order) => order['allow_states']);
          // get each order allow_states name
          var next_states = actions_states.map((e) => e.map((state) => state['name']));
          // next_states intersection
          var result = this.intersection(next_states);

          // get uniq state as string
          var uniq_state_strs = [...new Set(actions_states.flat().map((e) => JSON.stringify(e)))];
          // uniq_state_strs to json
          var uniq_states = uniq_state_strs.map((e) => JSON.parse(e));

          // each next_states trans to allow_states
          this.stateOptions = uniq_states.filter((e) => result.includes(e['name']));
        }
        else if (this.selectedAction == "printPDF") {
          this.readyAction = true;
        }
      },
      selectedStateChange: function () {
        if (this.selectedState != '')
          this.readyAction = true;
      },
      intersection: function (lists) {
        var result = [];

        for(var i = 0; i < lists.length; i++) {
          var currentList = lists[i];
          for(var y = 0; y < currentList.length; y++) {
              var currentValue = currentList[y];
            if(result.indexOf(currentValue) === -1) {
              var existsInAll = true;
              for(var x = 0; x < lists.length; x++) {
                if(lists[x].indexOf(currentValue) === -1) {
                  existsInAll = false;
                  break;
                }
              }
              if(existsInAll) {
                result.push(currentValue);
              }
            }
          }
        }
        return result;
      },
      onlyUnique: function(value, index, array) {
        return array.indexOf(value) === index;
      },
      clearSelected: function () {
        this.stateOptions = [];
        this.showPrintBtn = false;
        this.readyAction = false;
        this.selectedAction = "";
        this.selectedState = "";
      },
      doAction: function (e) {
        this[$("#selectedAction option:selected").data('action')]();
      },
      updateMany: function () {
        var that = this;
        var $from = $(".update-many-form");

        axios.post($from.attr('action'), $from.serialize())
        .then(function (response) {
          that.$emit('ordersChanged', response.data);
          that.clearSelected();
          that.orders = Object.values(response.data.items);
        })
        .catch(function (error) {
          alert('訂單修改失敗, 請聯絡專員');
        });
      },
      printPDF: function () {
        const url = new URL(`${location.protocol}//${location.host}/orders/print`);
        url.search = $.param({ 'numbers': this.order_numbers.join(',') });
        window.open(url.href);
      }
    }
  }
</script>

import Vue from 'vue/dist/vue.esm'
import PickupIndex from '../components/pickup_merchandises/PickupIndex'
document.addEventListener('DOMContentLoaded', () => {
  const element = document.querySelectorAll('[data-behavior="vue-pickup-app"]');
  // const elementEdit = document.querySelectorAll('[data-behavior="vue-trade-edit-app"]');
  if (element.length > 0) {
    const app = new Vue({
      el: '[data-behavior="vue-pickup-app"]',
      components: {
        'pickup_merchandise': PickupIndex
      }
    })
  }
})

<template>
  <form action="/trades/update_many" method="POST" class="update-many-form">
    <input type="hidden" name="authenticity_token" :value="csrf">
    <input type="hidden" name="trade_ids" v-model="trade_ids">
    <div class="row">
      <div class="col-12">
        <div class="form-group update-many-form-group mb-0">
          <div class="form-group-label">已選擇 {{trades.length}} 筆交易單</div>
          <div class="form-group-input" v-if="false">
            <div class="row">
              <div class="col-12 col-sm-3">
                <select class="form-control">
                  <option value=""></option>
                </select>
              </div>
              <div class="col-12 col-sm-3">
                <button type="submit" class="btn filter-action-btn btn-primary" v-if="false">更新</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
  export default {
    props: ["trades", "trade_ids"],
    data() {
      return {
        csrf: $('meta[name="csrf-token"]').attr('content')
      }
    }
  }
</script>

<template>
  <!-- 訂單商品 -->
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">商品詳情</div>
    </div>

    <div class="card-body order-item-container py-0">
      <div class="order-item" v-for="(item, index) in orderItems" :key="index">
        <div class="item-image">
          <img :src="item.img">
        </div>
        <div class="item-info">
          <div class="item-info-text">
            <span class="giveaway-bg" v-if="item.giveaway_id">贈品</span>
            <span>{{item.name}}</span>
            <span v-if="item.sku">(貨號: {{item.sku}})</span>
          </div>
          <div class="item-info-qty">
            <span>{{item.qty}}</span>
            <span class="ml-2 mr-2">x</span>
            <span>{{item.pirce}}</span>
            <span class="ml-2 mr-2">=</span>
            <span>{{item.total_price}}</span>
          </div>
        </div>
      </div>

      <div class="order-price">
        <div class="row">
          <div class="col-6">小計</div>
          <div class="col-6 text-right">{{orderPrice.merchandise_price}}</div>
        </div>

        <hr />

        <div class="row item-info">
          <div class="col-12">折扣</div>
        </div>

        <div class="row item-info mb-1" v-for="promotion in orderDetails.show_details.promotions">
          <div class="col-10 col-md-9">
            <span class="promotion-text">{{promotion.name}}</span>
          </div>
          <div class="col-2 col-md-3 text-right" v-if="promotion.discount_price">NT$-{{promotion.discount_price}}</div>
        </div>


        <div class="row shipping-fee">
          <div class="col-6">送貨費用</div>
          <div class="col-6 text-right">{{orderPrice.shipping_fee}}</div>
        </div>
        <div class="row total-price">
          <div class="col-6">合計</div>
          <div class="col-6 text-right">{{orderPrice.total}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    orderItems: {
      type: Array,
      default: () => {}
    },
    orderDetails: {
      type: Object,
      default: () => {}
    },
    orderPrice: {
      type: Object,
      default: () => {}
    },
  }
}
</script>

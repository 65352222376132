<template>
  <div class="trade-edit-container row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title">交易單明細</div>
          <!-- 如果有取消按鈕放在這 -->
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <div class="label-title">交易單編號</div>
                <div class="value-text">{{tradeDetails.number}}</div>
              </div>
              <div class="form-group">
                <div class="label-title">交易單日期</div>
                <div class="value-text">{{tradeDetails.created_date}}</div>
              </div>
              <div class="form-group">
                <div class="label-title">總金額</div>
                <div class="value-text">{{tradeDetails.total}}</div>
              </div>
              <div class="form-group states">
                <div class="label-title">訂單狀態</div>
                <div class="value-text">
                  {{tradeDetails.payment_state}}
                  <small v-if="tradeDetails.cancel_reason.length > 0">
                    ({{tradeDetails.cancel_reason}})
                  </small>
                </div>
              </div>
              <div class="form-group">
                <div class="label-title">付款方式</div>
                <div class="value-text">{{tradeDetails.payment_type}}</div>
              </div>
              <div class="form-group" v-if="tradeDetails.invoices.length > 0">
                <div class="label-title">發票號碼</div>
                <div class="value-text" v-for="invoice in tradeDetails.invoices">
                  {{invoice.number}}
                  <small>
                    {{invoice.kind_name}}
                    {{invoice.updated_date}}
                  </small>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <div class="label-title">訂購人</div>
                <div class="value-text">{{tradeOwner.name}}</div>
              </div>
              <div class="form-group">
                <div class="label-title">訂購人Email</div>
                <div class="value-text">{{tradeOwner.email}}</div>
              </div>

              <div class="form-group">
                <div class="label-title">電話號碼</div>
                <div class="value-text">{{tradeOwner.phone}}</div>
              </div>

              <div class="form-group states">
                <div class="label-title">訂購帳號</div>
                <div v-if="tradeOwner.user.is_login">
                  <div class="value-text">
                    <span>{{tradeOwner.user.nickname}} </span>
                    <small>({{tradeOwner.user.id}})</small>
                  </div>
                  <div class="value-text">{{tradeOwner.user.email}}</div>
                </div>
                <div v-else>
                  <div class="value-text" v-if="!tradeOwner.user.is_login">此會員未登入購物因此不享有會員相關優惠</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" v-for="(order, index) in ordersList" :key="index">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <div class="card-header-title">{{order.order_brand_shop_total.brand_shop_name}}</div>
            </div>
            <div class="col-6">
              <div class="card-header-title text-right">
                <a class="btn btn-sm btn-success btn-container" :href="`/order_returns/new?order_number=${order.order_num}`" v-if="order.refund_btn">退款資訊</a>
                <a class="btn btn-sm btn-info btn-container"    :href="`/order_returns/new?order_number=${order.order_num}`" v-if="order.cancel_btn">取消訂單</a>
                <a class="btn btn-sm btn-warning btn-container" :href="`/order_returns/new?order_number=${order.order_num}`" v-if="order.return_btn">訂單退貨</a>

                <span class="ml-2">{{order.order_brand_shop_total.total}}</span>

                <button class="btn ml-2" :id="`showHideBtn${index}`" @click="showHideBtn(order)">
                  <div v-show="order.show_hide == 'hide'">
                    <i class="fas fa-angle-down"></i>
                  </div>
                  <div v-show="order.show_hide == 'show'">
                    <i class="fas fa-angle-up"></i>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div :class="order.show_hide"  :id="`orderTradeContainer${index}`">
        <order-edit
          :order_number="order.order_num"
          :order_items="order.order_items"
          :order_owners="order.order_owner"
          :order_details="order.order_details"
          :order_price_details="order.order_shipping_details"
          :order_histories="order.order_histories"
          :is_super_admin="is_super_admin"
          :from_trade="true"
          />
      </div>
    </div>
  </div>
</template>

<script>
import OrderEdit from '../orders/OrderEdit';
import itemBtn from '../shared/tableComponents/tableItemsComponents/itemBtn';
import $ from 'jquery';
import { orderMixin } from '../orders/orderMixin.js'

export default {
  mixins: [orderMixin],
  components: {
    "order-edit": OrderEdit,
    "show-btn": itemBtn
  },
  props: {
    orders: {
      type: Array,
      default: () => []
    },
    is_super_admin: {
      type: Boolean,
      default: true
    },
    tradeDetails: {
      type: Object,
      default: () => { }
    },
    tradeOwner: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      ordersList: [...this.orders],
      showHidebtnName: {
        'show': 'fa-angle-down',
        'hide': 'fa-angle-up'
      }
    }
  },
  methods: {
    showHideBtn(order) {
      if (order.show_hide == 'hide') {
        order.show_hide = 'show'
      } else {
        order.show_hide = 'hide'
      }
    }
  }
}
</script>

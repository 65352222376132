import $ from 'jquery';
import Vue from 'vue';
import VueSimpleAlert from "vue-simple-alert";

Vue.use(VueSimpleAlert);
const axios = require('axios');
export const orderMixin = {
  methods: {
    orderUrlList(key, number) {
      const allUrl = {
        updateOrder: `/orders/${number}`
      };
      if (key == undefined) {
        return allUrl;
      }
      return allUrl[key];
    },
    successMsg(key) {
      const allMsg = {
        default: "已完成！",
        orderUpdateSuccess: "訂單更新完成!"
      };
      if (key == undefined) {
        return allMsg;
      }
      return allMsg[key] != undefined ? allMsg[key] : key;
    },
    errorMsg(key) {
      const allMsg = {
        default: "系統錯誤，請洽客服。",
        loginRequired: "請先登入唷！",
        orderUpdateError: "訂單更新失敗，請洽客服"
      };
      if (key == undefined) {
        return allMsg;
      }
      return allMsg[key] != undefined ? allMsg[key] : key;
    },
    alertMsg(msgKey = "default") {
      this.$alert(this.errorMsg(msgKey))
        .then(result => {
          location.reload();
        });
    },
    noticeMsg(msgKey = "default", reloadPage = false){
      this.$alert(this.successMsg(msgKey))
          .then(result => {
            if (reloadPage) {
              location.reload();
            }
          });
    },
    getCsrfToken(){
      const csrf = $('meta[name="csrf-token"]').attr('content');
      return {
        authenticity_token: csrf
      };
    },
    updateOrder(orderNum, params = {}) {
      const auth_token = this.getCsrfToken();
      return axios.patch(this.orderUrlList("updateOrder", orderNum), Object.assign(auth_token, params))
        .then((response) => {
          return response;
        });
    }
  }
};

<template>
  <div>
    <trade-edit-many
      :trades="selectedTrades"
      :trade_ids="selectedTradeIds"
      v-if="selectedTradeIds.length > 0"
    ></trade-edit-many>

    <table class="table table-hover">
      <thead>
        <tr>
          <th style="width: 40px;">
            <input type="checkbox" v-on:click="toggleAllTrade($event)">
          </th>
          <th>交易單號碼</th>
          <th>交易單日期</th>
          <th>付款狀態</th>
          <th>訂購人</th>
          <th>交易單金額</th>
          <th>utm_source</th>
          <th>utm_medium</th>
          <th>utm_campaign</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="trade in trades" :key="trade.id">
          <td>
            <input
              type="checkbox"
              class="trade-checkbox"
              :value="`${trade.id}`"
              v-model="selectedTradeIds"
              @change="selectTrade()"
            >
          </td>
          <th>
            <a :href="`/trades/${trade.id}`">
              {{ trade.number }}
            </a>
          </th>
          <td>{{ trade.created_at }}</td>
          <td class="states">
            <div class="label" :class="`${trade.payment_state}`">
              {{ states_options["payment_state"].find(element => element["code"] == trade.payment_state)["name"] }}
            </div>
          </td>
          <td>{{ trade.name }}</td>
          <td>{{ trade.total_price }}</td>
          <td>{{ trade.utm_source }}</td>
          <td>{{ trade.utm_medium }}</td>
          <td class='utm-campaign-info'>{{ trade.utm_campaign }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import TradeEditMany from './edit_many';

  export default {
    props: ["trades", "states_options"],
    components: {
      'trade-edit-many': TradeEditMany
    },
    data() {
      return {
        selectedTrades: [],
        selectedTradeIds: [],
        stateTypes: [
          { id: '', name: '' },
          { id: 'payment_state', name: '金流狀態' },
        ]
      }
    },
    methods: {
      selectTrade: function () {
        this.selectedTrades = this.trades.filter((trade) =>
          this.selectedTradeIds.includes(trade.id.toString())
        );
      },
      toggleAllTrade: function (e) {
        if (e.target.checked)
          $(".trade-checkbox:not(:checked)").trigger("click");
        else
          $(".trade-checkbox:checked").trigger("click");
      }
    }
  }
</script>

<style scoped>
tr {
  text-align: left;
}
.utm-campaign-info {
  width: 320px;
}
</style>

import Vue from 'vue/dist/vue.esm'
import CategoryIndex from '../components/categories/CategoryIndex'

document.addEventListener('DOMContentLoaded', () => {
  const element = document.querySelectorAll('[data-behavior="vue-category-app"]');

  if (element.length > 0) {
    const app = new Vue({
      el: '[data-behavior="vue-category-app"]',
      components: {
        CategoryIndex
      }
    })
  }
})

<template>
  <div>
    <div class="annoucementContainer">
      <div class="form-group-title">
        商品公告
      </div>
      <div class="form-container">
        <div class="form-group">
          <label>
            請輸入商店公告，此處只能顯示純文字，未輸入則前台不顯示
          </label>
          <textarea class="form-control" v-model="annoucement.content" rows="20"/>
        </div>

        <div class="bottomForm">
          <div class="form-group required">
            <label>公告日期區間</label>
            <date-picker
              v-model="publishRange"
              type="datetime"
              range
              style="width: 350px;"
              placeholder="公告日期區間"
            />
          </div>
          <div>
            <button class="btn btn-sm btn-success submit-cate-pick" :disabled="disabledPublish" @click="submitClick">
              儲存送出
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // https://www.npmjs.com/package/vue2-datepicker
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';

  const axios = require('axios');

  export default {
    props: {
      annoucementInit: {
        type: Object,
        default: () => {}
      }
    },
    components: {
      DatePicker
    },
    data: function () {
      return {
        date: '',
        annoucement: {...this.annoucementInit},
        publishRange: [],
        options: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
        },
        dateOptions: {
          start_at: {
            format: 'YYYY/MM/DD HH:mm',
            useCurrent: true,
          },
          end_at: {
            format: 'YYYY/MM/DD HH:mm',
            useCurrent: true,
            // minDate: false
          },
        }
      }
    },
    created() {
      // init pulish range by grouping start_at & end_at from annoucement
      this.initPublishRange()
    },
    watch: {
      publishRange(nV, oV) {
        const publishRange = this.publishRange
        if (this.publishRange && this.publishRange.length === 2) {
          this.annoucement.start_at = publishRange[0]
          this.annoucement.end_at = publishRange[1]
        } else {
          this.annoucement.start_at = ''
          this.annoucement.end_at = ''
        }
      },
    },
    computed: {
      disabledPublish() {
        return !(this.annoucement.start_at&&this.annoucement.end_at)
      }
    },
    methods: {
      initPublishRange() {
        if (this.annoucement.start_at && this.annoucement.end_at) {
          this.publishRange = [
            new Date(this.annoucement.start_at),
            new Date(this.annoucement.end_at)
          ]
        }
      },
      submitClick() {
        loading()
        const data = this.annoucement
        axios.post( '/shop_announcements',
          data,
        ).then((response) =>{
          this.$alert("儲存完成！");
          closeLoading();
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
.annoucementContainer{
  background: #fff;
  padding: 10px 30px;
}
.bottomForm{
  display: flex;
  justify-content: space-between;
}

</style>

<template>
  <form action="/orders" class="filter order-filter">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <div class="input-group">
            <div class="input-group-prepend">
              <button type="button" class="btn dropdown-toggle search-type" data-toggle="dropdown" aria-expanded="false">
                {{ currentSearchType.name }}
              </button>
              <ul class="dropdown-menu search-dropdown-menu" x-placement="bottom-start">
                <li
                  class="dropdown-item"
                  v-for="(search_type, index) in searchTypes"
                  :key="search_type.id"
                  v-on:click="setSearchType(index)"
                >{{ search_type.name }}</li>
              </ul>
            </div>
            <input
              type="text"
              class="form-control search-keywords"
              :placeholder="currentSearchType.placeholder"
              v-on:change="setSearchKeyword($event)"
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <div class="form-group-label">日期區間</div>
          <div class="form-group-input">
            <order-date-range-filter
              :inputId="`date_range`"
              :date_range="filters.q.date_range"
              v-on:rangeUpdated="setRange"
            ></order-date-range-filter>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <div class="form-group-label text-white">搜尋</div>
          <div class="form-group-input">
            <button type="button" class="btn filter-action-btn btn-primary" v-on:click="ordersSearch">搜尋</button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-6 col-md-4">
        <div class="form-group ">
          <div class="form-group-label">增加篩選條件</div>
          <div class="form-group-input">
            <select class="form-control" id="state_type" v-on:change="stateTypeChange($event)">
              <option value=""></option>
              <option value="state">訂單狀態</option>
              <option value="trade_payment_state">付款狀態</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-4">
        <div class="form-group">
          <div class="form-group-label text-white">篩選條件</div>
          <div class="form-group-input">
            <select
              class="form-control"
              id="state_options"
              v-if="currentStateType != ''"
              v-on:change="stateChange($event)"
            >
              <option value=""></option>
              <option
                v-for="state in stateOptions"
                :key="state.id"
                :value="state.code"
              >{{ state.name }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="filter-label-group" v-if="filterLabels.length > 0">
      <div class="form-group-title">
        已篩選條件:
        <a href="/orders" class="clear-all-link">清除所有篩選條件</a>
      </div>
      <div v-for="filter in filterLabels" :key="filter.name" class="label selected-label">
        {{ filter.name }}
        <span v-on:click="clearFilter(filter)" class="del-icon">
          <i class="fa fa-times"></i>
        </span>
      </div>
    </div>
  </form>
</template>

<script>
  import OrderDateRangeFilter from '../../shared/date_range_filter';

  export default {
    props: ["states_options", "url_params"],
    components: {
      'order-date-range-filter': OrderDateRangeFilter
    },
    data() {
      var filters = this.url_params;
      var that = this;

      var options = [
        {
          id: "number_or_order_shipping_address_name_or_order_shipping_address_phone_cont_any",
          title: "包含",
          multi: false
        }, {
          id: "number_cont_any",
          title: "訂單號碼",
          multi: true
        }, {
          id: "order_shipping_address_phone_cont_any",
          title: "電話",
          multi: true
        }, {
          id: "date_range",
          title: "日期範圍為",
          multi: false
        }, {
          id: "state_in",
          title: "訂單狀態",
          multi: true,
          mapping: "state"
        }, {
          id: "trade_payment_state_in",
          title: "付款狀態",
          multi: true,
          mapping: "trade_payment_state"
        }
      ];

      var filterLabels = [];
      options.forEach(function (option) {
        if (!option.multi) {
          if (filters["q"][option.id]) {
            filterLabels.push({
              id: option.id,
              name: `${option.title} ${filters["q"][option.id]}`
            });
          }
        } else {
          filters["q"][option.id].forEach(function (item) {
            if (option.mapping)
              name = that.states_options[option.mapping].find(element => element.code == item).name;
            else
              name = item;

            filterLabels.push({
              id: option.id,
              name: `${option.title} ${name}`,
              code: item
            })
          });
        }
      });

      var search_types = [{
          name: '搜尋關鍵字',
          id: 'number_or_order_shipping_address_name_or_order_shipping_address_phone_cont_any',
          placeholder: '可輸入消費者姓名、電話、訂單編號或商品名稱'
        }, {
          name: '搜尋多筆訂單',
          id: 'number_cont_any',
          placeholder: '輸入訂單號碼 (多筆以逗號分隔)'
        }, {
          name: '搜尋電話號碼',
          id: 'order_shipping_address_phone_cont_any',
          placeholder: '輸入收件人電話 (多筆以逗號分隔)'
        }
      ];

      filters["page"] = 1;

      return {
        filters: filters,
        filterLabels: filterLabels,
        searchTypes: search_types,
        stateOptions: [],
        currentStateType: "",
        currentSearchType: search_types[0]
      }
    },
    methods: {
      ordersSearch: function () {
        const url = new URL(`${location.protocol}//${location.host}${location.pathname}`);
        url.search = $.param(this.filters);
        window.location.href = url.href;
      },
      clearFilter: function (event) {
        if (event.code != undefined) {
          this.filters["q"][event.id] = this.filters["q"][event.id].filter(v => v != event.code);
        } else {
          this.filters["q"][event.id] = "";
        }
        this.ordersSearch();
      },
      stateTypeChange: function (e) {
        if (e.target.value != "") {
          this.currentStateType = e.target.value;
          this.stateOptions = this.states_options[this.currentStateType];
        }
      },
      stateChange: function (e) {
        if (e.target.value != "") {
          this.filters["q"][`${this.currentStateType}_in`].push(e.target.value);
          this.ordersSearch();
        }
      },
      setRange: function (e) {
        this.filters["q"]["date_range"] = e;
      },
      setSearchType: function (i) {
        this.currentSearchType = this.searchTypes[i];
      },
      setSearchKeyword: function (e) {
        if (this.currentSearchType.id == "number_or_order_shipping_address_name_or_order_shipping_address_phone_cont_any") {
          this.filters["q"][this.currentSearchType.id] = e.target.value;
        } else {
          this.filters["q"][this.currentSearchType.id] = e.target.value.split(',');
        }
      },
    }
  }
</script>

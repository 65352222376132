<template>

  <div class="card">
    <div class="card-header">
      <div class="card-header-title">訂單歷史</div>
    </div>

    <div class="card-body">
      <ul class="sessions">
        <li v-for="history in order_histories" :key="history.id">
          <div class="time">{{ history.datetime }}</div>
          <p>{{ history.content }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    is_super_admin: {
      type: Boolean,
      default: false
    },
    order_histories: {
      type: Array,
      defautt: () => []
    }
  },
  data() {
    return {
      orderHistories: [...this.order_histories]
    }
  }
}
</script>

<style scoped>
  ul, li {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
  }

  li {
    padding-bottom: .5rem;
    border-left: 1px solid #f0f0f0;
    position: relative;
    padding-left: 20px;
    margin-left: 10px;
  }

  li:last-child {
    border: 0px;
    padding-bottom: 0;
  }

  li:before {
    content: '';
    width: 15px;
    height: 15px;
    background: white;
    border: 1px solid #cccccc;
    box-shadow: 3px 3px 0px #f0f0f0;
    border-radius: 50%;
    position: absolute;
    left: -10px;
    top: 0px;
  }

  .time {
    color: #888888;
    font-size: 12px;
    font-weight: 500;
  }

  p {
    color: #333333;
    line-height: 1.5;
    font-size: 14px;
    margin-top: 5px;
  }
</style>

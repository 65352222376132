<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-6">
            <div class="card-header-title">訂單</div>
          </div>
          <div class="col-6 text-right">
            <div class="btn btn-default" v-on:click="dataExport">
              <i class="fa fa-download mr-1"></i>
              <span>匯出訂單明細</span>
            </div>
          </div>
        </div>
      </div>

      <div class="card-nav-tabs">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a href="/orders" class="nav-link active">訂單</a>
          </li>
          <li class="nav-item">
            <a href="/orders?query=returned" class="nav-link">退貨</a>
          </li>
          <li class="nav-item">
            <a href="/request_payments" class="nav-link">請款</a>
          </li>
        </ul>
      </div>

      <div class="overflow-auto orders-index">
        <div class="card-body">
          <order-index-filters
            :states_options="states_options"
            :url_params="params"
          ></order-index-filters>
        </div>
        <div class="card-body p-0">
          <order-index-table
            :states_options="states_options"
            :prop_orders="orders"
          ></order-index-table>
        </div>
      </div>
    </div>
    <b-pagination-nav
      :link-gen="linkGen"
      :number-of-pages="pages"
      use-router
    ></b-pagination-nav>
  </div>
</template>

<script>
  import { BPaginationNav } from 'bootstrap-vue'
  import OrderIndexTable from './index/table';
  import OrderIndexFilters from './index/filters';

  export default {
    props: ["orders", "per_page", "total", "states_options", "url_params"],
    components: {
      'b-pagination-nav': BPaginationNav,
      'order-index-table': OrderIndexTable,
      'order-index-filters': OrderIndexFilters
    },
    data() {
      var params = this.url_params;

      if (!params["q"])
        params["q"] = {};

      if (typeof params["q"]["number_cont_any"] != "object")
        params["q"]["number_cont_any"] = [];

      if (typeof params["q"]["order_shipping_address_phone_cont_any"] != "object")
        params["q"]["order_shipping_address_phone_cont_any"] = [];

      if (typeof params["q"]["date_range"] != "string")
        params["q"]["date_range"] = "";

      if (typeof params["q"]["state_in"] != "object")
        params["q"]["state_in"] = [];

      if (typeof params["q"]["trade_payment_state_in"] != "object")
        params["q"]["trade_payment_state_in"] = [];

      return {
        // currentPage: params["page"] ? +params["page"] : 1,
        pages: Math.ceil(this.total / this.per_page) || 1,
        params: params
      }
    },
    computed: {
      rows() {
        return this.total
      }
    },
    methods: {
      linkGen(pageNum) {
        let pairs = window.location.search.substring(1).split("&"),
            search_data = [];

        for (let i in pairs) {
          if ( pairs[i] === "" ) continue;
          if (pairs[i].split("=")[0] != "page")
            search_data.push(pairs[i]);
        }

        if (pageNum !== 1)
          search_data.push(`page=${pageNum}`);

        let path = "?";
        if (search_data.length > 0)
          path = `?${search_data.join('&')}`

        return path;
      },
      dataExport() {
        const url = new URL(`${location.protocol}//${location.host}/orders/export_data.xlsx`);
        url.search = $.param(this.params);
        window.location.href = url.href;
      }
    }
  }
</script>

<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-2">
            <div class="card-header-title">交易單</div>
          </div>
          <div class="col-10 text-right">
            <div class="btn btn-primary mr-2" v-on:click="reportExport('unstart_payment_accounting')">
              <i class="fa fa-download mr-1"></i>
              <span>尚未請款訂單明細(會計用)</span>
            </div>
            <div class="btn btn-info mr-2" v-on:click="reportExport('trade_accounting')">
              <i class="fa fa-download mr-1"></i>
              <span>交易單明細(會計用)</span>
            </div>
            <div class="btn btn-primary mr-2" v-on:click="reportExport('order_accounting')">
              <i class="fa fa-download mr-1"></i>
              <span>訂單明細(會計用)</span>
            </div>
            <div class="btn btn-default" v-on:click="reportExport('orders')">
              <i class="fa fa-download mr-1"></i>
              <span>銷售報表明細</span>
            </div>
            <div class="btn btn-default" v-on:click="dataExport">
              <i class="fa fa-download mr-1"></i>
              <span>交易單明細</span>
            </div>
          </div>
        </div>
      </div>

      <div class="overflow-auto trades-index">
        <div class="card-body">
          <trade-index-filters
            :states_options="states_options"
            :url_params="params"
            :brand_shops="brand_shops"
          ></trade-index-filters>
        </div>
        <div class="card-body p-0">
          <trade-index-table
            :states_options="states_options"
            :trades="trades"
          ></trade-index-table>
        </div>
      </div>
    </div>
    <b-pagination-nav
      :link-gen="linkGen"
      :number-of-pages="pages"
      use-router
    ></b-pagination-nav>
  </div>
</template>

<script>
  import { BPaginationNav } from 'bootstrap-vue'
  import TradeIndexTable from './index/table';
  import TradeIndexFilters from './index/filters';

  export default {
    props: ["trades", "per_page", "total", "states_options", "url_params", "brand_shops"],
    components: {
      'b-pagination-nav': BPaginationNav,
      'trade-index-table': TradeIndexTable,
      'trade-index-filters': TradeIndexFilters
    },
    data() {
      let params = this.url_params;

      if (!params["q"])
        params["q"] = {};

      if (typeof params["q"]["number_cont_any"] != "object")
        params["q"]["number_cont_any"] = [];

      if (typeof params["q"]["phone_cont_any"] != "object")
        params["q"]["phone_cont_any"] = [];

      if (typeof params["q"]["date_range"] != "string")
        params["q"]["date_range"] = "";

      if (typeof params["q"]["update_date_range"] != "string")
        params["q"]["update_date_range"] = "";

      if (typeof params["q"]["orders_update_date_range"] != "string")
        params["q"]["orders_update_date_range"] = "";

      if (typeof params["q"]["payment_state_in"] != "object")
        params["q"]["payment_state_in"] = [];

      if (typeof params["q"]["orders_brand_shop_id_eq"] != "string")
        params["q"]["orders_brand_shop_id_eq"] = "";

      return {
        // currentPage: params["page"] ? +params["page"] : 1,
        pages: Math.ceil(this.total / this.per_page) || 1,
        params: params
      }
    },
    computed: {
      rows() {
        return this.total
      }
    },
    methods: {
      linkGen(pageNum) {
        let pairs = window.location.search.substring(1).split("&"),
            search_data = [];

        for (let i in pairs) {
          if ( pairs[i] === "" ) continue;
          if (pairs[i].split("=")[0] != "page")
            search_data.push(pairs[i]);
        }

        if (pageNum !== 1)
          search_data.push(`page=${pageNum}`);

        let path = "?";
        if (search_data.length > 0)
          path = `?${search_data.join('&')}`

        return path;
      },
      reportExport(reportType) {
        const url = new URL(`${location.protocol}//${location.host}${location.pathname}/${reportType}.xlsx`);
        url.search = $.param(this.params);
        window.location.href = url.href;
      },
      dataExport() {
        const url = new URL(`${location.protocol}//${location.host}${location.pathname}.xls`);
        url.search = $.param(this.params);
        window.location.href = url.href;
      }
    }
  }
</script>

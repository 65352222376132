<template>
  <div>
    <!-- Button trigger modal -->
    <div class="text-sm cursor-pointer text-main-link mt-2" data-toggle="modal" :data-target="`#checkBack${number}`">
      <div class="fa fa-plus-circle"></div>
      商品搜尋
      <small v-if="tips.length>0">({{tips}})</small>
    </div>
    <!-- 贈品 popup -->
    <div class="modal fade" :id="`checkBack${number}`" tabindex="-1" role="dialog" aria-labelledby="giveawayModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="giveawayModalLabel">選取商品</h5>
          </div>
          <div class="modal-body">
            <div class="form-group mb-0">
              <div class="row">
                <div class="col-sm-12">
                  <span class="caution-content">商品需審核上線完成才能選取</span>
                  <div class="input-group mb-3">
                    <input v-model="searchText" class="form-control" placeholder="輸入商品關鍵字">
                    <div class="input-group-append">
                      <span class="input-group-text" @click="search">
                        <i class="fas fa-search" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="merchandise-list">
              <table class="table table-hover mb-0">
                <tbody>
                  <tr v-for="(product, index) in searchResult" :key="`merchandise${index}`">
                    <td class="align-middle" style="width: 40px;">
                      <input v-model="merchandiseIds" type="checkbox" :value="product.id" :disabled="!merchandiseIds.includes(product.id)&&disabledChecked" @click="selected(product, $event)">
                    </td>
                    <td class="align-middle" style="width: 100px;">
                      <img :src="product.image_url" class="product-image" />
                    </td>
                    <td class="align-middle">
                      {{ product.name }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
          <div class="px-4 mb-4">
            <button type="button" class="btn btn-sm btn-default" @click="refresh(defaultData)" >重整</button>
            <button type="button" class="btn btn-sm btn-default" data-dismiss="modal">取消</button>
            <button type="button" class="btn btn-sm btn-primary ml-2" data-dismiss="modal" @click="save(number)">確定</button>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>

export default {
  props: {
    number: {
      type: Number
    },
    defaultData: {
      type: Array,
      default: () => []
    },
    value: {
      type: Array,
      default: () => []
    },
    maxValue: {
      type: Number,
      default: 0
    },
    tips: {
      type: String,
      default: ''
    }
  },
  components: {

  },
  data: function () {
    return {
      searchText: "",
      searchResult: [...this.defaultData],
      selectProducts: []
    }
  },
  computed: {
    merchandiseIds: {
      get() {
        return this.value.map(v => +v)
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    disabledChecked() {
      if (this.maxValue > 0) {
        return this.merchandiseIds.length >= this.maxValue
      } else {
        return false
      }
    }
  },
  created() {
    this.setSelectedProducts()
  },
  methods: {
    setSelectedProducts() {
      this.selectProducts = this.defaultData.filter(pd => this.merchandiseIds.includes(pd.id))
    },
    search() {
      var keyword = this.searchText.trim();
      if (keyword == "") {
        return;
      }

      $.get( "/merchandises/search_merchandises?keyword=" + keyword, ( data ) => {
        this.refresh(data)
      });
    },
    save() {
      this.$emit('updateMerchandise', this.selectProducts, this.number);
    },
    selected(product, evt) {
      if (evt.target.checked) {
        this.selectProducts.push(product);
        this.$emit("addProduct", product)
      } else {
        this.selectProducts.filter(pd => pd.id !== product.id)
        this.$emit("removeProduct", product)
      }
    },
    refresh(initData) {
      this.searchResult = [...this.selectProducts]
      initData.forEach((d, _) => {
        if (!this.merchandiseIds.includes(d.id)) {
          this.searchResult.push(d)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.merchandise-list{
  max-height: 500px;
  overflow-y: scroll;
}
.input-group{
  margin-top: 10px;
}
.caution-content{
  color: red;
}
</style>

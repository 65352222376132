<template>
  <draggable
    class="category-group"
    ghost-class="ghost"
    :element="'ul'"
    :list="cateList"
    :options="{group:{ name: 'group0'}}"
    @start="dragging = true"
    @end="moved"
    :move="checkMove"
  >
    <li v-for="el in cateList" :key="el.id">
      <div class="row category-item align-items-center">
        <div class="col-6">
          <span class="handle">
            <i class="fas fa-bars"></i>
          </span>
          {{el.title}}
        </div>
        <div class="col-6 text-right cateEditLayout">
          <div v-if="editPicked" class="checkboxContainer">
            <input v-model="pickedIds" type="checkbox" :value="el.id" :disabled="!pickedIds.includes(el.id)&&disabledChecked" @click="selected(el, $event)">
          </div>

          <a :href="`/categories/${el.id}/edit`">
            <button class="btn btn-sm btn-default ml-1">編輯</button>
          </a>
          <a :href="`/categories/${el.id}/merchandises`">
            <button class="btn btn-sm btn-success ml-1">商品管理</button>
          </a>
          <button class="btn btn-sm btn-danger ml-1" @click="remove(el.id)">刪除</button>
        </div>
      </div>
      <nested-draggable :edit-picked="editPicked" v-model="pickedIds"  :picked-cats="pickedCatList" v-if="el.parent_id == null" :categories="el.childs" class="child" :isChild="true" :parentId="el.id"></nested-draggable>
    </li>
  </draggable>
</template>

<style lang="scss" scoped>
.category-group {
  list-style-type: none;
}
.category-item {
  background: rgba(230,230,230,0.2);
  border: 1px solid #e7e7e7;
  border-radius: 1px;
  padding: .3rem 0;
  font-size: 14px;
  color: #333333;
}
.category-item .handle {
  color: #ccd0d6;
  margin-right: .5rem;
  margin-left: .2rem;
  cursor: move;
}
.category-item .handle:hover {
  color: #333333;
}
.category-item:hover {
  border-left: 5px solid #333333;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.category-group.root {
  padding-inline-start: 0px;
}
.category-group.root li {
  margin-bottom: 0.5rem;
}
.category-group.child li {
  margin-bottom: 0;
}
.category-group.child li .category-item {
  border-top: none;
}

.cateEditLayout{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.checkboxContainer {
  margin-right: 10px;
  width: 15px;
  height: 15px;
  position: relative;
  input {
    width: 15px;
    height: 15px;
  }
}
</style>

<script>

import draggable from "vuedraggable";
import $ from 'jquery';

const axios = require('axios');
export default {
  props: {
    categories: {
      type: Array
    },
    isChild: {
      type: Boolean,
      default: false
    },
    parentId: {
      default: null
    },
    value: {
      type: Array,
      default: () => []
    },
    pickedCats: {
      type: Array,
      default: () => []
    },
    editPicked: {
      type: Boolean,
      default: false
    }
  },
  name: 'nested-draggable',
  display: "Simple",
  order: 0,
  components: {
    draggable
  },
  data: function() {
    return {
      cateList: this.categories,
    };
  },
  mounted(){
  },
  computed: {
    pickedIds: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input',v)
      }
    },
    pickedCatList: {
      get() {
        return this.pickedCats
      },
      set(v) {
        this.$emit('input', v)
      }
    },
    disabledChecked() {
      return this.pickedIds.length >= 5
    }
  },
  watch: {
    cateList: function(newChanged, oldChanged){
      var updatedCateList = []
      newChanged.forEach((items, index) => {
        items.position = index
        items.parent_id = this.parentId
        updatedCateList.push({
          id: items.id,
          position: items.position,
          parent_id: items.parent_id
        })
      })

      axios.post(`/categories/positions.json`,
        { positions: updatedCateList }
      )
      .then(function(response) {
      })
      .catch(function(error) {
        that.$alert('更新失敗');
      })
    }
  },
  methods: {
    remove: function(id) {
      var is_confirm = confirm('確定刪除？');
      if (is_confirm) {
        var that = this;
        axios.delete(`/categories/${id}.json`,
        )
        .then((response) => {
          this.cateList = this.cateList.filter(f => f.id !== id);
          location.reload();
        })
        .catch((error) => {
          this.$alert('刪除失敗');
        })
      }
    },
    moved: function(e) {

      // var that = this;
      // // console.log(e.from, e.to)
      // // console.log(e.item)
      // // if (e.to.classcateList.contains('child'))
      // //   this.level = 2;
      // // dragging = false;
      // var item = e.item

      // var data = this.cateList.map(function(item, index) {
      //   return { 'id': item.id, 'position': index }
      // });

      // //console.log(data)

      // // axios.post(`/categories/positions.json`,
      // //   { positions: data }
      // // )
      // // .then(function(response) {
      // // })
      // // .catch(function(error) {
      // //   that.$alert('更新失敗');
      // // })
      // //console.log("moved")

    },
    checkMove: function(evt) {
      /// return false 表示無法drag
      // ref: https://github.com/SortableJS/Vue.Draggable  (move)
      // ref: https://github.com/SortableJS/Sortable#event-object-demo (onMove)
      //console.log(evt)
      //console.log(evt.relatedContext.cateList)
      // 有小孩就不能成為別人小孩
      if (evt.draggedContext.element.childs != undefined && evt.draggedContext.element.childs.length > 0 ){
        // evt.to 回傳 目標的html
        //// 可判斷該 html 是否是「root」來決定是否允許 nested
        return $(evt.to).hasClass("root")
      }
    },
    selected(cate, evt) {
      if (evt.target.checked) {
        this.pickedCatList.push(cate)
      }
    },
  },
};
</script>

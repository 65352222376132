<template>
  <div>
    <input
      type="text"
      class="form-control"
      :id="inputId"
    >
  </div>
</template>

<script>
  export default {
    props: ["date_range", "inputId"],
    mounted: function() {
      var options = {
        autoUpdateInput: false,
        locale: {
          daysOfWeek:["日", "一", "二", "三", "四", "五", "六"],
        }
      };

      if (this.date_range != "") {
        var dates = this.date_range.split(' - ');
        options["startDate"] = dates[0];
        options["endDate"] = dates[1];
      }

      var that = this;
      $("#" + that.inputId).daterangepicker(
        options,
        function(start, end, label) {
          var value = `${start.format('YYYY/MM/DD')} - ${end.format('YYYY/MM/DD')}`
          $("#" + that.inputId).val(value);
          that.$emit('rangeUpdated', value);
        }
      );
    }
  }
</script>

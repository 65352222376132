// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

// node_modules
require("moment/locale/ja")
require("tempusdominus-bootstrap-4")
require("select2")
require("admin-lte")
require("axios")


// node_modules
import "bootstrap";
import "@fortawesome/fontawesome-free/js/all";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "daterangepicker"

// custom
import "stylesheets/application";
import "plugins";
import "homes";
import "trades";
import "categories";
import "orders";
import "order_returns";
import "pickup_merchandise";
import "shop_annoucement";

var jQuery = require("jquery");

// import jQuery from "jquery";
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


import Vue from 'vue';
import Multiselect from 'vue-multiselect';

$( document ).ready(function() {
  if ($("#app-header").length > 0) {
    var appHeader = new Vue({
      el: "#app-header",
      components: {
        "app-single-select": Multiselect
      },
      data: {
        shopSelectOptions: [],
        shopSelectValue: ""
      },
      mounted: function() {
        var appThis = this;
        $.get( "/merchandises/get_pay_shop", function( data ) {
          appThis.shopSelectOptions = data.shop_select;
          appThis.shopSelectValue = data.select;
        });
      },
      methods: {
        setShopValue(value) {
          $.get( "/homes/set_shop_id?shop_id=" + value.value, function( data ) {
            location.reload();
          });
        },
      }
    });
  }
});

<template>
  <div>
    <div class="tableAction">
      <order-edit-many
        :prop_orders="selectedOrders"
        v-if="selectedOrderNumbers.length > 0"
        @ordersChanged="onOrdersChange"
        class="filterContainer"
      ></order-edit-many>
      <div class="exportBtnContainer">
        <div v-if="selectedOrderNumbers.length > 0" class="btn btn-default" v-on:click="shipmentExport">
          <i class="fa fa-download mr-1"></i>
          <span>匯出出貨名條</span>
        </div>
      </div>

    </div>

    <table class="table table-hover">
      <thead>
        <tr>
          <th style="width: 40px;">
            <input type="checkbox" v-on:click="toggleAllOrder($event)">
          </th>
          <th>訂單號碼</th>
          <th>訂單日期</th>
          <th>應出貨日期</th>
          <th>訂單狀態</th>
          <th>付款狀態</th>
          <th>收件人</th>
          <th>訂單金額</th>
          <th>運送方式</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="order in orders" :key="order.number">
          <td>
            <input
              type="checkbox"
              class="order-checkbox"
              :value="`${order.number}`"
              v-model="selectedOrderNumbers"
              @change="selectOrder()"
            >
          </td>
          <td>
            <a :href="`/orders/${order.number}`">
              {{ order.number }}
            </a>
          </td>
          <td>{{ order.created_at }}</td>
          <td>{{ order.should_be_shipped_at }}</td>
          <td class="states">
            <div class="label" :class="`${order.state}`">
              {{ states_options["state"].find(element => element["code"] == order.state)["name"] }}
            </div>
          </td>
          <td class="states">
            <div class="label" :class="`${order.payment_state}`">
              {{ states_options["trade_payment_state"].find(element => element["code"] == order.payment_state)["name"] }}
            </div>
          </td>
          <td>{{ order.name }}</td>
          <td>{{ order.total_price }}</td>
          <td>{{ order.shipping_type_name }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import OrderEditMany from './edit_many';

  export default {
    props: ["prop_orders", "states_options"],
    components: {
      'order-edit-many': OrderEditMany
    },
    data() {
      return {
        orders: this.prop_orders,
        selectedOrders: [],
        selectedOrderNumbers: [],
        stateTypes: [
          { id: '', name: '' },
          { id: 'state', name: '訂單狀態' }
        ]
      }
    },
    methods: {
      selectOrder: function () {
        this.selectedOrders = this.orders.filter((order) =>
          this.selectedOrderNumbers.includes(order.number)
        );
      },
      toggleAllOrder: function (e) {
        if (e.target.checked)
          $(".order-checkbox:not(:checked)").trigger("click");
        else
          $(".order-checkbox:checked").trigger("click");
      },
      onOrdersChange: function (data) {
        var changed_orders = data.items;

        this.orders = this.orders.map((order) => {
          return (order.number in changed_orders) ? changed_orders[order.number] : order;
        });
        this.selectOrder();

        if (data.err_msg.length > 0)
          alert(data.err_msg);
      },
      shipmentExport() {
        const url = new URL(`${location.protocol}//${location.host}/orders/export_shipment_detail.xlsx?number=${this.selectedOrderNumbers.join(',')}`);
        url.search = $.param({number: this.selectedOrderNumbers.join(',') });
        window.location.href = url.href;
      }
    }
  }
</script>

<style lang="scss" scoped>
tr {
  text-align: left;
}
.tableAction{
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e9ecef;
  align-items: center;
  .filterContainer{
    width: 50%;
  }
  .exportBtnContainer{
    width: 50%;
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
  }
}
</style>

import Vue from 'vue/dist/vue.esm'
import ShopAnnoucement from '../components/shop_annoucements/AnnoucementIndex'
document.addEventListener('DOMContentLoaded', () => {
  const element = document.querySelectorAll('[data-behavior="vue-annoucement-app"]');
  // const elementEdit = document.querySelectorAll('[data-behavior="vue-trade-edit-app"]');
  if (element.length > 0) {
    const app = new Vue({
      el: '[data-behavior="vue-annoucement-app"]',
      components: {
        'shop_annoucement': ShopAnnoucement
      }
    })
  }
})

<template>

  <div class="card">
    <div class="card-header">
      <div class="card-header-title">收件人資訊</div>
    </div>

    <div class="card-body">
      <div class="form-group" v-for="(datas, name) in orderOwners.recipient.details" :key="name">
        <div class="label-title">{{datas.label}}</div>
        <input class="form-control" type="text" v-if="is_super_admin" v-model="datas.value">
        <div class="value-text" v-else>{{datas.value}}</div>
      </div>

      <div v-if="is_super_admin">
        <div class="form-group" v-for="(data, name) in orderOwners.recipient.address" :key="name">
          <div class="label-title">收件{{data.label}}</div>
          <input class="form-control" type="text" v-model="data.value">
        </div>
      </div>

      <div v-else-if="['ecpay_711_b2c', 'ecpay_711_c2c'].includes(orderOwners.recipient.shipment_type)">
        <div class="form-group" v-for="(data, name) in orderOwners.recipient.address" :key="name">
          <div class="label-title">收件{{data.label}}</div>
          <div class="value-text">{{data.value}}</div>
        </div>
      </div>

      <div v-else>
        <div class="form-group">
          <div class="label-title">收件人地址</div>
          <div class="value-text">
            <span>{{orderOwners.recipient.address.city.value}}</span>
            <span>{{orderOwners.recipient.address.district.value}}</span>
            <span>{{orderOwners.recipient.address.address1.value}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    is_super_admin: {
      type: Boolean,
      default: false
    },
    order_owners: {
      type: Object,
      defautt: () => {}
    }
  },
  data() {
    return {
      orderOwners: {...this.order_owners}
    }
  }
}
</script>

<template>
  <form action="/trades" class="filter order-filter">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <div class="input-group">
            <div class="input-group-prepend">
              <button type="button" class="btn dropdown-toggle search-type" data-toggle="dropdown" aria-expanded="false">
                {{ currentSearchType.name }}
              </button>
              <ul class="dropdown-menu search-dropdown-menu" x-placement="bottom-start">
                <li
                  class="dropdown-item"
                  v-for="(search_type, index) in searchTypes"
                  :key="search_type.id"
                  v-on:click="setSearchType(index)"
                >{{ search_type.name }}</li>
              </ul>
            </div>
            <input
              type="text"
              class="form-control search-keywords"
              :placeholder="currentSearchType.placeholder"
              v-on:change="setSearchKeyword($event)"
            >
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <div class="form-group-label">商店搜尋</div>
          <div class="form-group-input">
            <select class="form-control" id="brand_shop_id" v-model="filters.q.orders_brand_shop_id_eq">
              <option value=''>全部</option>
              <option v-for="brand_shop in brand_shops" :key="brand_shop.id" :value="brand_shop.id">{{ brand_shop.name }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 col-lg-3 form-group">
        <div class="form-group">
          <div class="form-group-label">utm_source</div>
          <div class="form-group-input">
            <input class="form-control" id="utm_source" v-model="filters.q.utm_source_cont">
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-lg-3 form-group">
        <div class="form-group">
          <div class="form-group-label">utm_medium</div>
          <div class="form-group-input">
            <input class="form-control" id="utm_medium" v-model="filters.q.utm_medium_cont">
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-lg-3 form-group">
        <div class="form-group">
          <div class="form-group-label">utm_campaign</div>
          <div class="form-group-input">
            <input class="form-control" id="utm_campaign" v-model="filters.q.utm_campaign_cont">
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <div class="form-group-label">交易單更新日期區間</div>
          <div class="form-group-input">
            <trade-date-range-filter
              :inputId="`date_range`"
              :date_range="filters.q.update_date_range"
              v-on:rangeUpdated="setTradeUpdateAtRange"
            ></trade-date-range-filter>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <div class="form-group-label">訂單更新日期區間</div>
          <div class="form-group-input">
            <trade-date-range-filter
              :inputId="`order_date_range`"
              :date_range="filters.q.orders_update_date_range"
              v-on:rangeUpdated="setOrderUpdateAtRange"
            ></trade-date-range-filter>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <div class="form-group-label">日期區間</div>
          <div class="form-group-input">
            <trade-date-range-filter
              :inputId="`date_range1`"
              :date_range="filters.q.date_range"
              v-on:rangeUpdated="setRange"
            ></trade-date-range-filter>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-1">
        <div class="form-group">
          <div class="form-group-label text-white">搜尋</div>
          <div class="form-group-input">
            <button type="button" class="btn filter-action-btn btn-primary" v-on:click="tradesSearch">搜尋</button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-6 col-md-4">
        <div class="form-group ">
          <div class="form-group-label">增加篩選條件</div>
          <div class="form-group-input">
            <select class="form-control state-select" id="state_type" v-on:change="stateTypeChange($event)">
              <option value=""></option>
              <option value="payment_state">付款狀態</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-4">
        <div class="form-group">
          <div class="form-group-label text-white">篩選條件</div>
          <div class="form-group-input">
            <select
              class="form-control state-select"
              id="state_options"
              v-if="currentStateType != ''"
              v-on:change="stateChange($event)"
            >
              <option value=""></option>
              <option
                v-for="state in stateOptions"
                :key="state.id"
                :value="state.code"
              >{{ state.name }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="filter-label-group" v-if="filterLabels.length > 0">
      <div class="form-group-title">
        已篩選條件:
        <a href="/trades" class="clear-all-link">清除所有篩選條件</a>
      </div>
      <div v-for="filter in filterLabels" :key="filter.name" class="label selected-label">
        {{ filter.name }}
        <span v-on:click="clearFilter(filter)" class="del-icon">
          <i class="fa fa-times"></i>
        </span>
      </div>
    </div>
  </form>
</template>

<script>
  import TradeDateRangeFilter from '../../shared/date_range_filter';

  export default {
    props: ["states_options", "url_params", "brand_shops"],
    components: {
      'trade-date-range-filter': TradeDateRangeFilter
    },
    data() {
      var filters = this.url_params;
      var that = this;

      var options = [
        {
          id: "name_or_number_or_phone_or_orders_number_cont_any",
          title: "包含",
          multi: false
        }, {
          id: "number_cont_any",
          title: "交易單號碼",
          multi: true
        }, {
          id: "phone_cont_any",
          title: "電話",
          multi: true
        }, {
          id: "date_range",
          title: "區間",
          multi: false
        }, {
          id: "update_date_range",
          title: "交易單更新日區間",
          multi: false
        }, {
          id: "orders_update_date_range",
          title: "訂單更新日區間",
          multi: false
        },{
          id: "payment_state_in",
          title: "付款狀態",
          multi: true,
          mapping: "payment_state"
        }, {
          id: "utm_campaign_cont",
          title: "utm_campaign",
          multi: false
        }, {
          id: "utm_medium_cont",
          title: "utm_medium",
          multi: false
        }, {
          id: "utm_source_cont",
          title: "utm_source",
          multi: false
        }
      ];

      var filterLabels = [];
      options.forEach(function (option) {
        if (!option.multi) {
          if (filters["q"][option.id]) {
            filterLabels.push({
              id: option.id,
              name: `${option.title}: ${filters["q"][option.id]}`
            });
          }
        } else {
          filters["q"][option.id].forEach(function (item) {
            if (option.mapping)
              name = that.states_options[option.mapping].find(element => element.code == item).name;
            else
              name = item;

            filterLabels.push({
              id: option.id,
              name: `${option.title}: ${name}`,
              code: item
            })
          });
        }
      });

      var search_types = [{
          name: '搜尋關鍵字',
          id: 'name_or_number_or_phone_or_orders_number_cont_any',
          placeholder: '可輸入訂購者姓名、電話、交易單編號或商品名稱'
        }, {
          name: '搜尋多筆交易單',
          id: 'number_cont_any',
          placeholder: '輸入交易單號碼 (多筆以逗號分隔)'
        }, {
          name: '搜尋電話號碼',
          id: 'phone_cont_any',
          placeholder: '輸入訂購者電話 (多筆以逗號分隔)'
        }
      ];

      filters["page"] = 1;

      return {
        filters: filters,
        filterLabels: filterLabels,
        searchTypes: search_types,
        stateOptions: [],
        currentStateType: "",
        currentSearchType: search_types[0]
      }
    },
    methods: {
      tradesSearch: function () {
        const url = new URL(`${location.protocol}//${location.host}${location.pathname}`);
        url.search = $.param(this.filters);
        window.location.href = url.href;
      },
      clearFilter: function (event) {
        if (event.code != undefined) {
          this.filters["q"][event.id] = this.filters["q"][event.id].filter(v => v != event.code);
        } else {
          this.filters["q"][event.id] = "";
        }
        this.tradesSearch();
      },
      stateTypeChange: function (e) {
        if (e.target.value != "") {
          this.currentStateType = e.target.value;
          this.stateOptions = this.states_options[this.currentStateType];
        }
      },
      stateChange: function (e) {
        if (e.target.value != "") {
          this.filters["q"][`${this.currentStateType}_in`].push(e.target.value);
          this.tradesSearch();
        }
      },
      setRange: function (e) {
        this.filters["q"]["date_range"] = e;
      },
      setTradeUpdateAtRange: function (e) {
        this.filters["q"]["update_date_range"] = e;
      },
      setOrderUpdateAtRange: function (e) {
        this.filters["q"]["orders_update_date_range"] = e;
      },
      setSearchType: function (i) {
        this.currentSearchType = this.searchTypes[i];
      },
      setSearchKeyword: function (e) {
        if (this.currentSearchType.id == "name_or_number_or_phone_or_orders_number_cont_any") {
          this.filters["q"][this.currentSearchType.id] = e.target.value;
        } else {
          this.filters["q"][this.currentSearchType.id] = e.target.value.split(',');
        }
      }
    }
  }
</script>

<style scoped>
#brand_shop_id {
  color: red;
}
</style>

<template>
  <div class="imageUploaderLayout" :style="`width: ${width}; height: ${height}`">
    <div v-if="imageUrl.length>0" class="imagePreviewWrapper" >
      <img :src="imageUrl">
      <div class="removeBtn" @click="removeImage">
        <div class="fa fa-times-circle" />
      </div>
    </div>
    <div v-else  class="uploaderBtnContainer">
      {{title}}
      <br>
      <small v-if="tips.length>0" class="tips">{{tips}}</small>
      <br>
      <label class="btn btn-default upload-btn">
        <i class="fa fa-plus-circle mr-1"></i>
        <span>上傳圖片</span>
        <input type="file" class="hide" @change="handleFileChange" accept=".jpg,.jpeg,.png"/>
      </label>
    </div>

  </div>
</template>

<script>

export default {
  props: {
    value: {

    },
    title: {
      type: String,
      default: ''
    },
    tips: {
      type: String,
      default: ''
    },
    limitSize: {
      type: Number,
      default: 1024 // 圖片上傳限制 1M
    },
    btnName: {
      type: String,
      default: '上傳圖片'
    },
    imgUrl: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '980px'
    },
    height: {
      type: String,
      default: '200px'
    }
  },
  data: function () {
    return {
      imageUrl: this.imgUrl
    }
  },
  computed: {
    file: {
      get() {
        return this.value
      },
      set(v){
        this.$emit('input', v)
      }
    }
  },
  watch: {

  },
  methods: {
    handleFileChange(event) {
      loading();
      if (event.target) {
        const file_size = event.target.files[0].size / 1024;
        if (!(file_size <= this.limitSize)){
          this.$alert("檔案大小需小於 1MB");
          closeLoading();
          return;
        }
        this.file = event.target.files[0]
        this.imageUrl = URL.createObjectURL(event.target.files[0])
      }
      closeLoading();
    },
    removeImage() {
      this.file = ''
      this.imageUrl = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.imageUploaderLayout{
  position: relative;
  background: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  max-width: 100%;
}
.imagePreviewWrapper{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
    max-height: 100%;
  }
  .removeBtn{
    position: absolute;
    top: 0;
    right: -10px
  }
}
.uploaderBtnContainer{
  text-align: center;
  .tips{
    font-size: 14px;
  }
  .upload-btn{
    margin-top: 20px;
  }
}
</style>

<template>
  <div>
    <template v-if="!isAdmin">
      <div class="pickedListWrapper">
        <div class="titleContainer">
          <div class="title">分類置頂清單</div>
          <ul>
            <li><span>☑️</span> 以下分類作為置頂選單，至多5個</li>
            <!-- <li>選完需點擊「確定置頂分類」送出才算更新完成</li> -->
          </ul>
        </div>
        <div v-if="pickedCates.length>0" class="pickedListContainer">
          <span v-for="(cate, idx) in pickedCates" :key="`pickedCat${idx}`" class="pickedCatItem">
            {{cate.title}}
          </span>
        </div>
        <div v-else class="emptyContent">
          尚無置頂分類
        </div>
        <button v-if="editPick" class="btn btn-sm btn-success submit-cate-pick" @click="submitPicked">
          確定 置頂 送出
        </button>
        <button v-else class="btn btn-sm btn-success submit-cate-pick" @click="editPick=true">
          編輯置頂
        </button>
      </div>
    </template>
    <div>
      <NestedDraggable
        v-model="pickedList"
        :categories=categories
        :picked-cats=pickedCates
        :edit-picked=editPick
        class="root"
      />
    </div>


  </div>
</template>


<script>

import NestedDraggable from './NestedDraggable'

const axios = require('axios');
export default {
  props: {
    isAdmin: Boolean,
    categories: {
      type: Array,
      default: () => []
    },
    pickedInit: {
      type: Array,
      default: () => []
    },
  },
  name: 'categoryIndex',
  display: "Simple",
  order: 0,
  components: {
    NestedDraggable
  },
  data: function() {
    return {
      pickedList: this.pickedInit.map(cat => cat.id),
      pickedCates: [...this.pickedInit],
      editPick: false
    };
  },
  computed: {
    disabledChecked() {
      return this.pickedList.length > 5
    }
  },
  watch: {
    pickedList(nV, oV) {
      this.pickedCates = this.pickedCates.filter(cat => nV.includes(cat.id))
    }
  },
  methods: {
    checkPicked(cate, evt) {
      if (evt.target.checked) {
        this.pickedList.push(cate.id);
        this.pickedList.push(cate);
      } else {
        this.pickedList.filter(ctId => ctId !== cate.id)
        this.pickedCates.filter(ct => ct.id !== cate.id )
      }
    },
    submitPicked() {
      loading()
      const data = {
        picked: this.pickedList
      }
      axios.post( '/categories/pickup_categories',
        data,
      ).then((response) =>{
        this.editPick = false;
        closeLoading();
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.pickedListWrapper{
  padding: 20px;
  position: relative;
  width: 100%;
  background: #f9f9f9;
  margin: 20px 0;
  .titleContainer{
    .title{
      font-size: 20px;
      text-align: left;
    }
  }
  .emptyContent{
    padding: 20px;
    text-align: center;
    font-size: 18px;
  }
  .submit-cate-pick{
    position: absolute;
    top: 20px;
    right: 20px;
  }
  ul {
    li {
      font-size: 14px;
      span{
        margin: 0 2px;
      }
    }
  }
}
.pickedListContainer{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    padding: 10px 15px;
    border: 1px solid #333;
    border-radius: 5px;
    margin: 0 10px;
  }

}
</style>

import Vue from 'vue/dist/vue.esm'
import TradesIndex from '../components/trades/TradesIndex'
import TradeEdit from '../components/trades/TradeEdit';
document.addEventListener('DOMContentLoaded', () => {
  const element = document.querySelectorAll('[data-behavior="vue-trade-app"]');
  const elementEdit = document.querySelectorAll('[data-behavior="vue-trade-edit-app"]');
  if (element.length > 0) {
    const app = new Vue({
      el: '[data-behavior="vue-trade-app"]',
      components: {
        'trades': TradesIndex
      }
    })
  }

  // Trade Edit
  if (elementEdit.length > 0) {
    const app = new Vue({
      el: '[data-behavior="vue-trade-edit-app"]',
      components: {
        'trade-edit': TradeEdit
      }
    })
  }
})

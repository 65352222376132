$(function () {
  if ($("#return_order_dialog").length > 0) {
    $('#return_submit').click(function () {
      var data = $('#order_return_submit').serialize();
      $.ajax({
        url: $('#order_return_submit').attr('action'),
        type: "POST",
        data: $('#order_return_submit').serialize(),
        success: function () {
          window.location.href = $("#back_path").val();
        },
        error: function (data) {
          alert(data.msg);
        }
      });
    });

    $('.city-id-select').change(function () {
      if (!$(this).val()) {
        return;
      }
      $(".city_name").val($('.city-id-select :selected').text());
      $.ajax({
        url: '/regions.json?city_id=' + $(this).val(),
        method: 'GET',
        success: function (data) {
          var options;
          options = '<option value=""></option>'
          $.each(data, function (k, v) {
            options += '<option value="' + v.id + '">' + v.name + '</option>';
          });
          $('.region-id-select').html(options);
        },
        error: function () {
          return alert('沒有資料');
        }
      });
    });

    $('.region-id-select').change(function () {
      $(".district_name").val($('.region-id-select :selected').text());
    })
  }
});

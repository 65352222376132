<template>
  <div class="SelectBar">
    <app-single-select
      :id="select_id_name"
      :class="select_class_list"
      v-model="searchValue"
      :options="select_options"
      label="name"
      :multiple="multiple_select"
      :group-label="groupLabel"
      :group-values="groupValue"
      :group-select="groupSelect"
      :track-by="track_by"
      :disabled="disabled_select"
      :searchable="search_able"
      :hideSelected="select_hide"
      :placeholder="select_placeholder"
      :show-labels="show_select_label"
      @select="emitChanged($event)"/>
    <i :class="select_icon"></i>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  props: {
    selected_value: {
      type: Object,
      default: null
    },
    select_options: {
      type: Array,
      default: () => []
    },
    select_placeholder: {
      type: String,
      default: ""
    },
    select_change: {
      type: String,
      default: "search_changed"
    },
    select_id_name: {
      type: String,
      default: null
    },
    select_class_list: {
      type: String,
      default: ""
    },
    select_icon: {
      type: String,
      default: "fa fa-search"
    },
    select_hide: {
      type: Boolean,
      default: false
    },
    multiple_select: {
      type: Boolean,
      default: false
    },
    group_props: {
      type: Object,
      default: () => {}
    },
    search_able: {
      type: Boolean,
      default: true
    },
    track_by: {
      type: String,
      default: "value"
    },
    emit_type: {
      type: String,
      default: "value"
    },
    show_select_label: {
      type: Boolean,
      default: true
    },
    disabled_select: {
      type: Boolean,
      default: false
    }
  },
  components: {
    "app-single-select" : Multiselect
  },
  data(){
    return{
      searchValue: undefined,
      groupLabel: "",
      groupValue: "",
      groupSelect: false
    }
  },
  methods: {
    emitChanged(evt){
      if (this.emit_type == 'value') {
        this.$emit("search_changed", evt[this.track_by])
      } else if (this.emit_type == 'event') {
        this.$emit("search_changed", evt)
      }

    },
    checkIsGroupOptions(){
      if (this.group_props == undefined){
        return
      }
      const groupProps = this.group_props
      if (Object.keys(groupProps).length > 0){
        this.groupLabel = groupProps.label
        this.groupValue = groupProps.value
        this.groupSelect = groupProps.ableSelect
      }
    },
    checkSelectedValue(){
      if (this.selected_value != null) {
        this.searchValue = {...this.selected_value}
      }

    }
  },
  mounted(){
    this.checkIsGroupOptions()
    this.checkSelectedValue()
  }
}
</script>
